import { Component } from '@angular/core';

@Component({
  selector: 'app-materiel-propose',
  templateUrl: './materiel-propose.component.html',
  styleUrls: ['./materiel-propose.component.scss']
})
export class MaterielProposeComponent {

}
