<div id="home" class="choice-home-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choice-home-content">
                    <h1>Demander un devis</h1>
                    <p class="text-white">Vous avez des questions ? Nous avons les réponses !</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="contact" class="let-contact-area with-main-color">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="let-contact-form">
                    <h3 id="contant-form">Contactez nous pour plus d'information</h3>
                    <br>
                    <div *ngIf="successMessage" class="alert alert-warning">{{ successMessage }}</div>
                    <form [formGroup]="form" id="contactForm">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <label>Nom</label>
                                    <input type="text" name="name" id="name" formControlName="name"
                                           class="form-control {{ (name.invalid && (name.dirty || name.touched)) ? 'is-invalid' : '' }} "
                                           required
                                           data-error="Please enter your name" placeholder="William">
                                    <div class="invalid-feedback" *ngIf="name.errors?.['required']">
                                        Votre nom est obligatoire.
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" name="email" id="email" formControlName="email"
                                           class="form-control {{ (email.invalid && (email.dirty || email.touched)) ? 'is-invalid' : '' }}"
                                           required
                                           data-error="Please enter your email" placeholder="levi@gmail.com">
                                    <div class="invalid-feedback" *ngIf="email.errors?.['required']">Adresse email
                                        obligatoire.
                                    </div>
                                    <div class="invalid-feedback" *ngIf="email.errors?.['email']">
                                        Adresse email invalide.
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Téléphone</label>
                                    <input type="text" name="phone" formControlName="phone" id="phone" required
                                           data-error="Numéro de téléphone" class="form-control"
                                           placeholder="Numéro de téléphone">
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <div class="form-group">
                                    <label>Votre Message</label>
                                    <textarea name="message" class="form-control" formControlName="message" id="message"
                                              cols="30" rows="8"
                                              required data-error="Write your message"
                                              placeholder="write your message"></textarea>
                                    <div class="invalid-feedback" *ngIf="message.errors?.['required']">Tapez votre
                                        message.
                                    </div>
                                    <div class="invalid-feedback" *ngIf="message.errors?.['minlength']">Minimum 10
                                        caractères
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <div class="let-contact-btn">
                                    <button *ngIf="!loading" type="submit" [disabled]="!form.valid" class="main-default-btn" (click)="sendMessage()">
                                        Envoyez votre message
                                    </button>
                                    <span *ngIf="loading" class="sub-loader">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             xmlns:xlink="http://www.w3.org/1999/xlink"
                                             style="margin:auto;background:transparent;display:block;" width="40px"
                                             height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<defs><mask id="ldio-21vjsxtand9-mask"><circle cx="50" cy="50" r="45" fill="#44979d"></circle></mask></defs>
<circle cx="50" cy="50" r="45" fill="#44979d"></circle>
<path d="MNaN NaN ANaN NaN 0 0 1 NaN NaN LNaN NaN LNaN NaN Z" fill="#306a6e" mask="url(#ldio-21vjsxtand9-mask)"></path>
<circle cx="50" cy="50" r="30" stroke="#44979d" stroke-width="10" fill="none"></circle>
<circle cx="50" cy="50" r="30" stroke="#ffffff" stroke-width="10" stroke-linecap="square" fill="none">
  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1"
                    values="0 50 50;180 50 50;720 50 50"></animateTransform>
  <animate attributeName="stroke-dasharray" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1"
           values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882"></animate>
</circle>
</svg>
                                    </span>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="let-contact-map">
                    <iframe src="https://www.google.com/maps/d/u/2/embed?mid=1Osk6G1lPYpNytgyCXtGXsw0-ChzNlNUh"
                            frameborder="0" width="100%" height="550" style="margin-top:-50px"></iframe>
                </div>
            </div>
        </div>
    </div>

    <div class="let-contact-shape">
        <img src="assets/img/more-home/let-contact-shape.png" alt="image">
    </div>
</div>
