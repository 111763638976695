import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-activities",
    templateUrl: "./activities.component.html",
    styleUrls: ["./activities.component.scss"],
})
export class ActivitiesComponent implements OnInit {
    fragment: string;

    activities = [
        {
            image: "assets/img/img_13.png",
            title: `Papier et Carton`,
            text: `Notre collecte de papier et carton vise à valoriser ces matériaux précieux tout en réduisant la
            demande en fibres vierges. Ces déchets sont acheminés vers des centres de recyclage où
            ils sont triés, compactés et transformés en nouvelles matières premières. La finalité est de
            prolonger le cycle de vie du papier et du carton, contribuant ainsi à la préservation des forêts
            et à la réduction des émissions de CO2.
            `,
        },
        {
            image: "/assets/img/img_9.png",
            title: "Plastique",

            text: `La collecte des déchets plastiques chez DMT est une démarche cruciale pour
            lutter contre la pollution et promouvoir le recyclage. Ces plastiques sont dirigés vers des
            installations de recyclage spécialisées où ils sont transformés en granulés. Ces granulés
            peuvent être utilisés pour fabriquer de nouveaux produits plastiques, réduisant ainsi la
            dépendance aux plastiques vierges et minimisant l'impact environnemental.
            `,
        },
        {
            image: "/assets/img/img_17.png",
            title: "DIB (Déchets Industriels Banals)",

            text: `Les déchets industriels banals, comprenant des matériaux non dangereux, sont collectés
            par DMT pour être acheminés vers des centres de tri. Ce processus permet de séparer les
            différents composants, facilitant le recyclage des métaux, du bois, du textile, etc. La
            valorisation de ces déchets contribue à préserver les ressources naturelles et à réduire la
            quantité de déchets envoyés en décharge.
            `,
        },
        {
            image: "/assets/img/verre.jpg",
            title: "Verre",

            text: `Notre collecte de verre vise à maximiser la récupération de ce matériau 100% recyclable.
            Les déchets en verre sont dirigés vers des centres de traitement où ils sont fondus pour être
            transformés en nouveaux produits en verre. Cette démarche économe en énergie permet de
            préserver les ressources naturelles, tout en réduisant les émissions de gaz à effet de serre
            `,
        },
        {
            image: "/assets/img/Biodechets-1.png",
            title: "Bio-déchets",

            text: `La collecte des bio-déchets s'inscrit dans une logique de gestion durable des déchets
            organiques. Ces déchets sont dirigés vers des centres de compostage où ils subissent un
            processus de décomposition contrôlé pour produire un compost riche en nutriments. Ce
            compost peut ensuite être utilisé comme fertilisant naturel, favorisant ainsi la circularité des
            ressources et la réduction des déchets.
            `,
        },
    ];

    constructor(private route: ActivatedRoute) {}

    scrollTo(element: any): void {
        (document.getElementById(element) as HTMLElement).scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    }

    ngOnInit(): void {
        this.route.fragment.subscribe((fragment) => {
            this.fragment = fragment;
            try {
                if (this.fragment) {
                    document.querySelector("#" + this.fragment).scrollIntoView();
                }
            } catch (e) {}
        });
    }

}
