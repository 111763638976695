import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PreloaderComponent} from './components/preloader/preloader.component';
import { ProfessionnalComponent } from './pages/professionnal/professionnal.component';
import { ActivitiesComponent } from './pages/activities/activities.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import {HomeComponent} from './pages/home/home.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ActualityComponent } from './pages/actuality/actuality.component';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {ReactiveFormsModule} from "@angular/forms";
import { TruncatePipe } from './core/pipes/truncate.pipe';
import { MaterielProposeComponent } from './pages/materiel-propose/materiel-propose.component';
import { ReglementationsComponent } from './pages/reglementations/reglementations.component';

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        HomeComponent,
        ProfessionnalComponent,
        ActivitiesComponent,
        NavbarComponent,
        FaqComponent,
        ContactComponent,
        ActualityComponent,
        TruncatePipe,
        MaterielProposeComponent,
        ReglementationsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
