import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    private env = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    contact(data: any) {
        return this.http.post(`${this.env}/contact`, data, {
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            }
        });
    }
}
