<app-preloader></app-preloader>
<app-navbar></app-navbar>
<router-outlet></router-outlet>
<footer class="footer-style-area pt-100 ">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <div class="footer-logo">
                        <a href="#">
                            <img src="assets/img/logo.png" alt="Logo">
                        </a>
                        <ul class="footer-social">
                            <li>
                                <a href="https://www.facebook.com/sharer/sharer.php?app_id=309437425817038&sdk=joey&u=http%3A%2F%2Fwww.dmt-recyclage.fr/accueil-recyclage-de-dechet-paris.php"
                                   target="_blank">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/share"
                                   data-url="http://www.dmt-recyclage.fr/accueil-recyclage-de-dechet-paris.php"
                                   target="_blank">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://plus.google.com/share?url=http%3A%2F%2Fwww.dmt-recyclage.fr/accueil-recyclage-de-dechet-paris.php%2F%23.VDP-D0ol2xo.google_plusone_share&t=Neue+mode+,+grossiste+tissus+et+mercerie+France+Belgique."
                                   target="_blank">
                                    <i class='bx bxl-google-plus'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/uas/connect/user-signin?session_redirect=https%3A%2F%2Fwww%2Elinkedin%2Ecom%2Fcws%2Fshare%3FisFramed%3Dfalse%26lang%3Den_US%26url%3Dhttp%253A%252F%252Fwww.dmt-recyclage.fr/accueil-recyclage-de-dechet-paris.php%26original_referer%3Dhttp%253A%252F%252F127%252E0%252E0%252E1%252Fneue-mode%252Ebe%252Fv1%252Faccueil-grossiste-tissus-france-belgique%252Ephp%26token%3D%26_ts%3D1412693201204%252E9148"
                                   target="_blank">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>VOUS ÊTES</h3>

                    <ul class="quick-link">
                        <li>
                            <a routerLink="/" [queryParams]="{scrollTo: 'you_are_1',}" queryParamsHandling="merge">Professionnel</a>
                        </li>
                        <li>
                            <a routerLink="/" [queryParams]="{scrollTo: 'you_are_2', }" queryParamsHandling="merge">Collectivités ou Syndics</a>
                        </li>
                        <li>
                            <a routerLink="/" [queryParams]="{scrollTo: 'you_are_3',}" queryParamsHandling="merge">Particulier</a>
                        </li>

                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Nos activités
                    </h3>

                    <ul class="quick-link">
                        <li>
                            <a routerLink="/services" [queryParams]="{'scrollTo': 'collecte_et_recyclage'}" queryParamsHandling="merge">Collecte & Recyclage</a>
                        </li>
                        <li>
                            <a routerLink="/services" [queryParams]="{'scrollTo': 'location_de_benne'}" queryParamsHandling="merge">Location de Benne
                            </a>
                        </li>

                        <li>
                            <a routerLink="/services" [queryParams]="{'scrollTo': 'debarras'}" queryParamsHandling="merge">Débarras et Déblaiement
                            </a>
                        </li>
                        <li>
                            <a routerLink="/services" [queryParams]="{'scrollTo': 'demantelement'}" queryParamsHandling="merge">Démantèlement industriel
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Support</h3>

                    <ul class="quick-link">
                        <li>
                            <a routerLink="/faq">FAQ</a>
                        </li>
                        <li>
                            <a routerLink="/contact" fragment="contant-form">Contact & Devis</a>
                        </li>
                        <li>
                            <a routerLink="/actuality">Actualités</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <h3>Adresse</h3>

                    <ul class="address-info">
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:0982381798">0 982 381 798</a>
                        </li>
                        <li>
                            <i class='bx bx-message-square-detail'></i>
                            <a href="mailto:contact@dmt-recyclage.fr">contact@dmt-recyclage.fr</a>
                        </li>
                        <li>
                            <i class='bx bx-current-location'></i>
                            15 Rue de la Guivernone, 95310 Saint-Ouen-l'Aumône
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
