<div class="materiel-propose-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="choice-home-content">
                    <h1>Materiel Proposé</h1>
                    <p class="text-white">
                        DMT Recyclage est une entreprise spécialisée dans la
                        collecte et le recyclage des déchets.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<section id="services" class="one-service-area ptb-100">
    <div class="container">

        <nav>
            <div id="nav-tab" role="tablist" class="nav nav-tabs">
                <a
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    href="#nav-home"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                    class="nav-item nav-default active"
                ><div class="service-item">
                    <img
                        src="/assets/img/materielprpose/Plan_de_travail_1.webp"
                        alt="image" />
                    <span>Bac de Déchets Plastique</span>
                </div></a
                ><a
                id="nav-profile-tab"
                data-bs-toggle="tab"
                href="#nav-profile"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
                class="nav-item nav-default"
                tabindex="-1"
            ><div class="service-item">
                <img
                    src="/assets/img/materielprpose/Plan_de_travail_1_copie.webp"
                    alt="image" />
                <span>Caisse Palette Plastique 660L</span>
            </div></a>
                <a
                id="nav-contact-tab"
                data-bs-toggle="tab"
                href="#nav-contact"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
                class="nav-item nav-default"
                tabindex="-1"
            ><div class="service-item">
                <img
                    src="/assets/img/materielprpose/Plan_de_travail_1_copie_2.webp"
                    alt="image" />
                <span>Roll Métallique 1m³ </span>
            </div></a>
                <a
                id="nav-x-tab"
                data-bs-toggle="tab"
                href="#nav-x"
                role="tab"
                aria-controls="nav-x"
                aria-selected="false"
                class="nav-item nav-default"
                tabindex="-1"
            ><div class="service-item">
                <img
                    src="/assets/img/materielprpose/Plan_de_travail_1_copie_3.webp"
                    alt="image" />
                <span>Benne de 10, 15 et 20m³</span>
            </div></a>
                <a
                id="nav-y-tab"
                data-bs-toggle="tab"
                href="#nav-y"
                role="tab"
                aria-controls="nav-y"
                aria-selected="false"
                class="nav-item nav-default"
                tabindex="-1"
            ><div class="service-item">
                <img
                    src="/assets/img/materielprpose/Plan_de_travail_1_copie_4.webp"
                    alt="image" />
                <span>Points d'Apport Volontaire (PAV)</span>
            </div></a>
                <a
                id="nav-z-tab"
                data-bs-toggle="tab"
                href="#nav-z"
                role="tab"
                aria-controls="nav-z"
                aria-selected="false"
                class="nav-item nav-default"
                tabindex="-1"
            ><div class="service-item">
                    <img
                        src="/assets/img/materielprpose/Plan_de_travail_1_copie_5.webp"
                        alt="image" />
                <span>Bioseau pour Tri des Déchets Organiques</span>
            </div></a>

            </div>
        </nav>
        <div id="nav-tabContent" class="tab-content">
            <div
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
                class="tab-pane fade show active"
            >
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="/assets/img/materielprpose/1-1.png"
                                    alt="Advisor"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Bac de Déchets Plastique : Solution de Stockage Polyvalente</h2>
                                <p>
                                    Le bac de déchets plastique offre une solution robuste et polyvalente pour le stockage des déchets dans divers environnements professionnels. Disponible en volumes de <strong>240</strong>, <strong>340</strong>, <strong>660</strong>, <strong>750</strong> et <strong>1000</strong> litres, ce bac est adapté pour une utilisation dans les immeubles de bureaux, les entrepôts industriels et sur les chantiers de construction. Il permet de stocker une variété de déchets, tels que :
                                </p>
                                <ul>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Les déchets industriels banals (DIB)
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Le carton
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                       Le plastique et le verre
                                    </li>
                                </ul>
                                <p>
                                    grâce à son design ergonomique et à ses couvercles de différentes couleurs facilitant le tri sélectif
                                </p>
                                <h4>Services Associés :</h4>
                                <ul>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Option avec Timon pour une Mobilité Optimale : Optez pour l'option avec timon pour une facilité de déplacement accrue du bac sur votre site, offrant ainsi une flexibilité maximale dans votre processus de gestion des déchets.

                                    </li>
                                </ul>
                                <div class="col-lg-5">
                                    <a routerLink="/contact"
                                       fragment="contant-form"
                                       class="cmn-banner-btn d-block mt-3 btn-success btn-sm"><i
                                        class="bx bx-note"></i>Demander un devis</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
                class="tab-pane fade"
            >
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="/assets/img/caissepalette.png"
                                    alt="Advisor"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Caisse Palette Plastique 660 Litres : Stockage Polyvalent pour Divers Flux de Déchets</h2>
                                <p>La caisse palette plastique de 660 litres offre une solution robuste et polyvalente pour le stockage et le transport efficace de divers flux de déchets. Adaptée pour une utilisation dans les immeubles de bureaux,Les entrepôts industriels et Les chantiers de construction.</p>
                                <ul>
                                    <li>
                                        <ul>
                                            <li>
                                                <i class="bx bxs-chevrons-right"></i>
                                                Les déchets d'équipements électriques et électroniques (DEEE)</li>
                                            <li>
                                                <i class="bx bxs-chevrons-right"></i>
                                                Le carton</li>
                                            <li>
                                                <i class="bx bxs-chevrons-right"></i>
                                                Les encombrants</li>
                                            <li>
                                                <i class="bx bxs-chevrons-right"></i>
                                                Le papier</li>
                                            <li>
                                                <i class="bx bxs-chevrons-right"></i>
                                                Les sacs</li>
                                            <li>
                                                <i class="bx bxs-chevrons-right"></i>
                                                Archives confidentielles ou conventionnelles</li>
                                        </ul>
                                    </li>
                                    <li>Son design ergonomique permet une manipulation aisée au transpalette et offre la possibilité de gerber les caisses pour optimiser l'espace de stockage.</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="nav-contact"
                role="tabpanel"
                aria-labelledby="nav-contact-tab"
                class="tab-pane fade"
            >
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="assets/img/home-one/advisor3.jpg"
                                    alt="Advisor"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Roll Métallique 1m³ : Stockage Polyvalent pour Collecte de Papier, Bouteilles Plastiques, Canettes et Encombrants
                                </h2>
                                <p>
                                    Le roll métallique d'1m³ offre une solution de stockage polyvalente et robuste pour la collecte de différents types de déchets dans les immeubles à plusieurs locataires. Idéal pour la collecte de papier de bureau, de bouteilles plastiques, de canettes et d'encombrants, ce roll métallique facilite le tri sélectif et le stockage efficace des déchets.
                                    Sa conception métallique assure une durabilité et une résistance optimales, tandis que sa taille compacte permet de le placer facilement dans les espaces restreints des immeubles.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="nav-x"
                role="tabpanel"
                aria-labelledby="nav-x-tab"
                class="tab-pane fade"
            >
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="assets/img/home-one/advisor4.jpg"
                                    alt="Advisor"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Benne de 10, 15 et 20m³ : Solution Polyvalente pour la Gestion des Déchets</h2>
                                <p>
                                    Les bennes de 10, 15 et 20m³ offrent une solution robuste et polyvalente pour la gestion efficace des déchets dans différents environnements, tels que les bureaux, les chantiers de construction et les sites industriels. Adaptées à une variété de déchets, ces bennes sont idéales pour le débarras de mobilier de bureau, le stockage de papier, d'archives confidentielles, de DEEE, de déchets bois, de DIB, de gravats et de plastiques. Leur grande capacité de stockage permet de collecter et de transporter efficacement de grandes quantités de déchets, facilitant ainsi le processus de gestion des déchets.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="nav-y"
                role="tabpanel"
                aria-labelledby="nav-y-tab"
                class="tab-pane fade"
            >
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="assets/img/home-one/advisor5.jpg"
                                    alt="Advisor"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Points d'Apport Volontaire (PAV) : Encouragez le Tri des Déchets dans vos Bureaux</h2>
                                <p>
                                    Les Points d'Apport Volontaire (PAV) sont des installations stratégiquement placées dans vos bureaux et couloirs pour encourager et faciliter le tri sélectif des déchets. Ces points de collecte sont équipés de bacs de tri colorés et clairement identifiés pour le papier, le plastique, le verre et les déchets encombrants, offrant ainsi une solution pratique pour la séparation des déchets à la source. En incitant les utilisateurs de l'immeuble à trier leurs déchets de manière responsable, les PAV contribuent à promouvoir une culture d'entreprise écologiquement responsable et à réduire l'empreinte environnementale de votre entreprise.

                                </p>
                                <h4>Caractéristiques des Points d'Apport Volontaire </h4>
                                <p>
                                    - Facilité d'Accès : Les PAV sont installés dans des zones à fort passage, accessibles à tous les occupants de l'immeuble pour une utilisation pratique et efficace.
                                    - Identification Visuelle : Chaque bac de tri est clairement identifié avec des pictogrammes et des couleurs spécifiques pour une reconnaissance facile des différents flux de déchets.
                                </p>
                                <ul>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Respect for all people
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Excellence in everything we do
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Truthfulness in our business
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Unquestionable integrity
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="nav-z"
                role="tabpanel"
                aria-labelledby="nav-z-tab"
                class="tab-pane fade"
            >
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="assets/img/home-one/advisor6.jpg"
                                    alt="Advisor"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Bioseau pour Tri des Déchets Organiques : Une Solution Pratique pour les Restaurants d'Entreprise</h2>
                                <p>
                                    Le Bioseau est un contenant spécialement conçu pour la collecte sélective des déchets organiques dans les refectories et les cuisines des restaurants d'entreprise. Il offre une solution pratique et hygiénique pour trier les déchets alimentaires, les épluchures et autres matières organiques, conformément à la législation en vigueur depuis 2024 qui oblige au tri des déchets organiques. Notre service comprend la fourniture des Bioseaux ainsi que leur collecte régulière sur demande, permettant ainsi de maintenir un environnement propre et respectueux de l'environnement dans les locaux de restauration.

                                </p>
                                <h4>Caractéristiques du Bioseau</h4>
                                <p>
                                    - Collecte sur Demande : Notre équipe assure la collecte régulière des Bioseaux sur demande, offrant ainsi une flexibilité maximale dans la gestion des déchets organiques.
                                    - Conformité Légale : En conformité avec la loi en vigueur depuis 2024, notre service de Bioseau permet aux restaurants d'entreprise de respecter les obligations de tri des déchets organiques, contribuant ainsi à la préservation de l'environnement.
                                </p>
                                <ul>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Respect for all people
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Excellence in everything we do
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Truthfulness in our business
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Unquestionable integrity
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
