import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ActivitiesComponent } from "./pages/activities/activities.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { HomeComponent } from "./pages/home/home.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { ActualityComponent } from "./pages/actuality/actuality.component";
import { MaterielProposeComponent } from "./pages/materiel-propose/materiel-propose.component";
import { ReglementationsComponent } from "./pages/reglementations/reglementations.component";

const routes: Routes = [
    { path: "", component: HomeComponent, runGuardsAndResolvers: "always" },
    {
        path: "services",
        component: ActivitiesComponent,
        runGuardsAndResolvers: "always",
    },
    {
        path: "materiel-propose",
        component: MaterielProposeComponent,
    },
    {
        path: "reglementations",
        component: ReglementationsComponent,
    },
    {
        path: "faq",
        component: FaqComponent,
    },
    {
        path: "contact",
        component: ContactComponent,
    },
    {
        path: "actuality",
        component: ActualityComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
