import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../core/services/shared.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    form: FormGroup;
    loading = false;
    successMessage: string;
    fragment: string;

    constructor(private sharedService: SharedService, private fb: FormBuilder, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            name: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.email]),
            message: new FormControl('', [Validators.required, Validators.minLength(10)]),
            phone: new FormControl('', []),
            subject: new FormControl('', []),
        });
        this.route.fragment.subscribe((fragment) => {
            this.fragment = fragment;
            try {
                if (this.fragment) {
                    document.querySelector("#" + this.fragment).scrollIntoView();
                }
            } catch (e) {}
        });
    }

    get name() {
        return this.form.get('name');
    }

    get email() {
        return this.form.get('email');
    }

    get message() {
        return this.form.get('message');
    }

    sendMessage() {
        if (!this.form.valid) {
            return;
        }

        this.loading = true;
        this.sharedService.contact(this.form.value).pipe(
            finalize(() => this.loading = false)
        ).subscribe({
            next: response => {
                this.successMessage = 'Votre message est envoyé avec succès.';
                setTimeout(() => {
                    this.successMessage = undefined;
                }, 5000);
            },
            error: errors => {
                console.log(errors);
            }
        });
    }

}
