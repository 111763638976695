import { ViewportScroller } from "@angular/common";
import {
    AfterContentChecked,
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, AfterViewChecked {
    @ViewChild("popup") popup: ElementRef;
    fragment: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private viewportScroller: ViewportScroller
    ) {}

    contanctUs() {
        this.popup?.nativeElement.click();
        this.router.navigate(["/contact"]);
    }

    scrollTo(element: any): void {
        this.viewportScroller.scrollToAnchor(element);
    }

    ngOnInit(): void {
        this.route.fragment.subscribe((fragment) => {
            this.fragment = fragment;
            try {
                if (this.fragment) {
                    document.querySelector("#" + this.fragment).scrollIntoView();
                }
            } catch (e) {}
        });
    }

    ngAfterViewChecked(): void {

    }
    
    @ViewChild('myVideo') myVideo: ElementRef;

    ngAfterViewInit() {
        this.myVideo.nativeElement.muted = true;
    }
}
